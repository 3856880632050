'use client';

import Link from 'next/link';

import useECText from '@features/shared/ectext';

import css from '../features/error/404.module.css';

type GlobalErrorProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function Page({ error }: GlobalErrorProps) {
  const ECText = useECText();

  const message =
    error?.message ?? ECText.getText(78741, '500: Unexpected error');

  return (
    <div className={css.container}>
      <h1>{ECText.getText(78742, 'Problems loading the page')}</h1>
      <p>{message}</p>
      <p>
        <Link href="/" replace={true}>
          {ECText.getText(-1, 'Back to site overview')}
        </Link>
      </p>
    </div>
  );
}
